import Form from "../atoms/enroll-form"
import FormSection from "../atoms/enroll-form-section"
import FormLabel from "../atoms/enroll-form-label"
import FormLabelText from "../atoms/enroll-form-label-text"
import FormInput from "../atoms/enroll-form-input"
import FormCheckbox from "../atoms/enroll-form-checkbox"
import FormExpend from "../atoms/enroll-form-expend"
import Recaptcha from "react-recaptcha"
import FormSectionBtn from "../atoms/enroll-form-section-btn"
import FormBtn from "../atoms/enroll-form-btn"
import errorLabel from "../molecules/enroll-form-error"
import React from "react"

const form = props => (
  <Form onSubmit={props.handleSubmit}>
    <FormSection>
      <FormLabel>
        <FormLabelText>
          Adres e-mail <span>*</span>
        </FormLabelText>
        <FormInput
          placeholder={"jan.kowalski@poczta.pl"}
          type={"text"}
          name={"email"}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.email}
          required={true}
          error={props.errors.email && props.touched.email}
          success={!props.errors.email && props.values.email}
        />
        {props.errors.email &&
          props.touched.email &&
          errorLabel(props.errors.email)}
      </FormLabel>
      <FormLabel>
        <FormLabelText>
          Twoje imię <span>*</span>
        </FormLabelText>
        <FormInput
          placeholder={"Jan"}
          type={"text"}
          name={"name"}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.name}
          required={true}
          error={props.errors.name && props.touched.name}
          success={!props.errors.name && props.values.name}
        />
        {props.errors.name &&
          props.touched.name &&
          errorLabel(props.errors.name)}
      </FormLabel>
    </FormSection>
    <FormSection long>
      <FormLabel $featured="true">
        <FormLabelText>
          Zgoda na przetwarzanie danych osobowych <span>*</span>
        </FormLabelText>
        <FormInput
          type={"checkbox"}
          name={"zgoda1"}
          onChange={e => {
            props.handleChange(e)
            props.setTouched({ zgoda1: true })
          }}
          onBlur={props.handleBlur}
          checked={props.values.zgoda1}
          required={true}
        />
        <FormCheckbox>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={"20px"}
              height={"20px"}
              viewBox="0 0 24 24"
            >
              <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
            </svg>
          </span>
        </FormCheckbox>
        <FormExpend>
          Wyrażam zgodę na przetwarzanie danych osobowych w celu przeprowadzenia
          rekrutacji do szkoły językowej na podstawie art. 6 ust. 1 lit. a
          Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
          kwietnia 2016 roku w sprawie ochrony osób fizycznych w związku z
          przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
          takich danych oraz uchylenia dyrektywy 95/46/WE oraz art. 23 ust. 1
          pkt 1 ustawy z dnia 29 sierpnia 1997 roku o ochronie danych osobowych
          (t.j. Dz.U. 2016 poz. 922 ze zm.). Administratorem danych osobowych
          jest Aleksandra Sydor prowadząca działalność gospodarczą pod nazwą
          Amuzo Aleksandra Sydor siedzibą w Pyskowicach przy ul. Wojska
          Polskiego 25, nr NIP 9691541386.
        </FormExpend>
        {props.errors.zgoda1 &&
          props.touched.zgoda1 &&
          errorLabel(props.errors.zgoda1)}
      </FormLabel>
      <FormLabel $featured="true">
        <FormLabelText>
          Zgoda na otrzymywanie informacji handlowych <span>*</span>
        </FormLabelText>
        <FormInput
          type={"checkbox"}
          name={"zgoda2"}
          onChange={e => {
            props.handleChange(e)
            props.setTouched({ zgoda2: true })
          }}
          onBlur={props.handleBlur}
          checked={props.values.zgoda2}
        />
        <FormCheckbox>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={"20px"}
              height={"20px"}
              viewBox="0 0 24 24"
            >
              <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
            </svg>
          </span>
        </FormCheckbox>
        <FormExpend>
          Wyrażam zgodę na otrzymywanie informacji handlowych drogą
          elektroniczną na podstawie ustawy z dnia 18 lipca 2002 r. o
          świadczeniu usług drogą elektroniczną, w szczególności ofert,
          informacji o promocjach, rabatach, bieżących wydarzeniach i akcjach
          marketingowych, od Aleksandry Sydor prowadząca działalność gospodarczą
          pod nazwą Amuzo Aleksandra Sydor siedzibą w Pyskowicach przy ul.
          Wojska Polskiego 25, nr NIP 9691541386.
        </FormExpend>
        {props.errors.zgoda2 &&
          props.touched.zgoda2 &&
          errorLabel(props.errors.zgoda2)}
      </FormLabel>
      <p>
        Używamy MailerLite jako naszej platformy do automatyzacji marketingu.
        Klikając poniżej "Dodaj do newslettera", potwierdzasz, że podane przez
        Ciebie informacje zostaną przesłane do MailerLite w celu przetworzenia
        zgodnie z ich{" "}
        <a
          href={"https://www.mailerlite.com/legal/privacy-policy"}
          target={"_blank"}
          rel={"noopener noreferrer"}
        >
          Polityką prywatności
        </a>{" "}
        i{" "}
        <a
          href={"https://www.mailerlite.com/legal/terms-of-service"}
          target={"_blank"}
          rel={"noopener noreferrer"}
        >
          Warunkami korzystania z usługi
        </a>
        .
      </p>
      <FormLabel>
        <Recaptcha
          sitekey={"6LfIjaYUAAAAAE2HkOdskilgAP6iWf5UhTHqXa69"}
          render={"explicit"}
          onloadCallback={() => 1}
          verifyCallback={response => {
            props.setFieldValue("recaptcha", response)
          }}
          expiredCallback={() => {
            props.setFieldValue("recaptcha", "")
            props.setFieldError("recaptcha", "Weryfikacja wygasła.")
          }}
        />
        {props.errors.recaptcha &&
          props.touched.recaptcha &&
          errorLabel(props.errors.recaptcha)}
      </FormLabel>
    </FormSection>
    <FormSectionBtn>
      <FormBtn type={"reset"} onClick={props.handleReset}>
        Wyczyść
      </FormBtn>
      <FormBtn type={"submit"} disabled={!props.isValid} $featured="true">
        Dodaj do newslettera
      </FormBtn>
    </FormSectionBtn>
  </Form>
)

export default form

import styled from "styled-components"

export default styled.section`
  flex-basis: 3%;
  span {
    width: 20px;
    height: 20px;
    border: 2px solid ${({ theme }) => theme.pink};
    border-radius: 3px;
    background: transparent;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  svg {
    display: none;
  }
`;
import StatusHeading from "../atoms/enroll-status-heading"
import StatusText from "../atoms/enroll-status-text"
import React from "react"

export default props => (
  <>
    <StatusHeading>
      {props.title}
    </StatusHeading>
    <StatusText>
      {props.children}
    </StatusText>
  </>
);
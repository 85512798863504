import styled from "styled-components"

export default styled.p`
  flex-basis: 95%;
  margin: 0;
  
  @media(max-width: 650px) {
    flex-basis: 93%;
  }
  
  @media(max-width: 600px) {
    flex-basis: 90%;
  }
`;
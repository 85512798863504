import styled from "styled-components"

export default styled.p`
  font-size: 1.6rem;
  margin: 0;
  width: 60%;
  
  @media(max-width: 600px) {
    width: 100%;
  }
`;
import styled from "styled-components"

export default styled.section`
  flex-basis: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5vh;
  
  @media(max-width: 1100px) {
    justify-content: center;
  }
  
  @media(max-width: 350px) {
    flex-wrap: wrap-reverse;
  }
`;
import styled, { css } from "styled-components"

export default styled.button`
  flex-basis: 20%;
  padding: 2rem;
  border: none;
  color: ${({ theme }) => theme.pink};
  background: transparent;
  cursor: pointer;
  font-weight: 600;
  transition: all 200ms cubic-bezier(0.4, 0, 0, 1);
  white-space: nowrap;

  @media (max-width: 350px) {
    flex-basis: 100%;
  }

  :hover {
    background: ${({ theme }) => theme.grey};
  }

  :focus {
    outline: none;
    box-shadow: 0 0 8px -2px rgba(239, 125, 186, 1);
  }

  :disabled,
  :disabled:hover,
  &[disabled],
  &[disabled]:hover {
    opacity: 0.7;
    box-shadow: none;
    background: ${({ theme }) => theme.pink};
    cursor: default;
  }

  ${({ $featured, theme }) =>
    $featured &&
    css`
      background: ${theme.pink};
      color: ${theme.white};
      margin-left: 5vw;
      :hover {
        background: #ba4d8a;
        color: white;
        box-shadow: 0 0 8px -2px rgba(239, 125, 186, 1);
      }

      @media (max-width: 350px) {
        margin-left: 0;
        margin-bottom: 5vh;
      }
    `}
`

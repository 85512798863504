import React, { Component } from "react"
import ParagraphItalic from "../atoms/paragraph-italic"
import Heading from "../atoms/newsletter-heading"
import Wrapper from "../atoms/page-wrapper"
import styled from "styled-components"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import ActionLink from "../atoms/action-paragraph-link"
import { endpoint } from "../../api"
import form from "../molecules/newsletter-form-page"
import EnrollStatus from "../molecules/enroll-status"
import Advice from "../atoms/table-advice"
import AdviceContent from "../atoms/table-advice-content"

const Container = styled.article`
  margin-top: 1vh;
`

const FormSchema = Yup.object().shape({
  email: Yup.string()
    .email("Nieprawidłowy adres email.")
    .required("Pole wymagane."),
  name: Yup.string()
    .min(2, "Imię jest za krótkie.")
    .max(50, "Imię za długie.")
    .required("Pole wymagane."),
  zgoda1: Yup.boolean()
    .oneOf([true], "Musisz wyrazić zgodę na przetwarzanie danych osobowych.")
    .required("Zgoda jest wymagana."),
  zgoda2: Yup.boolean()
    .oneOf([true], "Musisz wyrazić zgodę na otrzymywanie informacji handlowych drogą elektroniczną.")
    .required("Zgoda jest wymagana."),
  recaptcha: Yup.string()
    .required("Musisz potwierdzić, że jesteś człowiekiem."),
})

class NewsletterPage extends Component {

  state = {
    status: "form",
    email: "",
  }

  componentWillMount() {
    if (typeof window !== `undefined`) {
      if (window.history.state !== null) {
        this.setState({ email: window.history.state.email })
      }
    }
  }

  componentDidMount() {
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
    script.async = true
    script.defer = true
    document.body.appendChild(script)
  }

  onSubmit = (values, actions) => {
    axios.post(
      endpoint.enrollSubmit,
      { ...values, type: "newsletter" },
    )
      .then((response) => {
        if (response.data === "ok") {
          actions.resetForm()
          this.formSuccess()
        } else {
          this.formFail()
        }
      })
      .catch(() => {
        this.formFail()
      })
  }

  formFail = () => {
    this.setState({ status: "fail" })
  }

  formSuccess = () => {
    this.setState({ status: "success" })
  }

  formEmbed = () => (
    <Formik
      initialValues={
        {
          email: this.state.email,
          name: "",
          zgoda1: "",
          zgoda2: "",
          recaptcha: "",
        }
      }
      onSubmit={this.onSubmit}
      validationSchema={FormSchema}
      render={form}
    />
  )

  handleStatus = () => {

    if (this.state.status === "form") {
      return this.formEmbed()
    }

    if (this.state.status === "fail") {
      return (
        <>
          {this.formEmbed()}
          <EnrollStatus title={"Coś poszło nie tak..."}>
            Nie udało się dodać Cię do listy naszych subskrybentów. Spróbuj ponownie raz jeszcze za
            kilka chwil.
            <br/>
            <br/>
            Jeśli ten błąd się powtarza, prosimy o <ActionLink to={"/kontakt"} title={"Strona kontaktowa"}>kontakt</ActionLink>.
          </EnrollStatus>
        </>
      )
    }

    if (this.state.status === "success") {
      return (
        <>
          <EnrollStatus title={"Udało się!"}>
            Za subskrybowałeś naszego newslettera. Wysłaliśmy już do Ciebie wiadomość e-mail w tej sprawie.
          </EnrollStatus>
        </>
      )
    }

    return this.formEmbed()
  }

  render = () => (
    <Wrapper>
      <ParagraphItalic>zapisz-się</ParagraphItalic>
      <Heading>Zapisz się do naszego newslettera</Heading>

      <Container>
        {
          this.handleStatus()
        }
      </Container>

      <Advice>Chcesz się wypisać z newslettera?</Advice>
      <AdviceContent>Jeśli chcesz zrezygnować z otrzymywania informacji handlowych drogą elektroniczną, prosimy
        o <ActionLink to={"/kontakt"} title={"Strona kontaktowa"}>wysłanie do nas wiadomości</ActionLink> w tej sprawie.</AdviceContent>
    </Wrapper>
  )
}

export default NewsletterPage
import styled, { css } from "styled-components"

export default styled.section`
  flex-basis: 45%;
  
  ${({ long }) => long && css`
    flex-basis: 100%;
  `};
  
  @media(max-width: 1100px) {
    flex-basis: 100%;
  }
`;